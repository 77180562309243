<template>
  <div clas="rich-text-field form-element">
    <h3 class="form-input-title">
      {{ fieldTitle }}
    </h3>
    <div v-if="editor">
      <editor-content class="rich-text-field__editor"
                      :class="{focused: isFocused, error: errorText && errorText !== ''}"
                      :editor="editor"
                      @click="editor.chain().focus().run()"
                      @mouseup="mouseUpTextEditor" />
      <div v-if="showTooltip" :style="tooltipStyle" class="tooltip">
        <div class="tooltip-contents">
          <p class="tooltip-link-alias">{{ selectedLink.name }}</p>
          <p class="tooltip-link-url"><a :href="selectedLink.url" target="_blank">{{ selectedLink.url }}</a></p>
          <div class="tooltip-buttons">
            <button class="tooltip-remove tooltip-button" @click="deleteLink">
              <img src="@/assets/icons/icn_delete.svg">
              <p>Verwijderen</p>
            </button>
            <button class="tooltip-edit tooltip-button" @click="editLink">
              <img src="@/assets/icons/icn_edit.svg">
              <p>Bewerken</p>
            </button>
          </div>
        </div>
        <div class="tooltip-arrow"></div>
      </div>
      <div class="rich-text-field__menu-bar">
        <button class="rich-text-field__menu-bar-button" :class="{ 'is-active': editor.isActive('bold') }" @click.prevent="editor.chain().focus().toggleBold().run()">
          <img src="@/assets/icons/icn_bold.svg" class="rich-text-field__menu-bar-button-icon">
        </button>
        <button class="rich-text-field__menu-bar-button" :class="{ 'is-active': editor.isActive('italic') }" @click.prevent="editor.chain().focus().toggleItalic().run()">
          <img src="@/assets/icons/icn_italic.svg" class="rich-text-field__menu-bar-button-icon">
        </button>
        <button class="rich-text-field__menu-bar-button" :class="{ 'is-active': editor.isActive('link'), 'disabled': getSelectedText().length === 0 }" @click.prevent="setLink">
          <img src="@/assets/icons/icn_link.svg" class="rich-text-field__menu-bar-button-icon">
        </button>
        <button class="rich-text-field__menu-bar-button" :class="{ 'is-active': editor.isActive('underline') }" @click.prevent="editor.chain().focus().toggleUnderline().run()">
          <img src="@/assets/icons/icn_underline.svg" class="rich-text-field__menu-bar-button-icon">
        </button>
        <div class="rich-text-field__menu-bar-right" @click="showEmojis = !showEmojis">
          <img src="@/assets/icons/icn_emoji.svg" class="rich-text-field__menu-bar-button-icon">
        </div>
        <EmojiPicker
          v-if="showEmojis"
          class="emoji-picker"
          :show_arrow="false"
          @emoji_click="setEmoji"
        />
      </div>
    </div>
    <div v-if="errorText && errorText !== ''" class="text-error">
      <p> {{ errorText }} </p>
    </div>
    <UpdateLinkModal v-if="updateLinkModalActive" ref="updateLinkModal" :selected-text="getSelectedText()" :link-value="getLinkHref()" />
  </div>
</template>

<script>
import { EditorContent, useEditor } from '@tiptap/vue-3'
import CharacterCount from '@tiptap/extension-character-count'
import Placeholder from '@tiptap/extension-placeholder'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Link from '@tiptap/extension-link'
import Bold from '@tiptap/extension-bold'
import Underline from '@tiptap/extension-underline'
import { computed, onUnmounted, ref } from '@vue/runtime-core'
import UpdateLinkModal from '@/components/elements/RichTextEditor/UpdateLinkModal'
import { useStore } from 'vuex'
import {
  RESET_BASIC_MODAL_DATA,
  SET_BASIC_MODAL_DATA
} from '@/store/mutations'
import { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import EmojiPicker from '@/components/elements/RichTextEditor/EmojiPicker'

export default {
  name: 'RichTextField',
  components: {
    EditorContent,
    UpdateLinkModal,
    EmojiPicker
  },
  props: {
    fieldTitle: {
      type: String,
      required: false,
      default: ''
    },
    inputCallback: {
      type: Function,
      required: false,
      default: () => {}
    },
    value: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    errorText: {
      type: String,
      required: false,
      default: ''
    },
    maxLength: {
      type: Number,
      required: false,
      default: null
    }
  },
  setup(props) {
    const store = useStore()

    const editor = useEditor({
      content: props.value,
      extensions: [
        StarterKit,
        Link.configure({ openOnClick: false }),
        Document,
        Paragraph,
        Text,
        Bold,
        Underline,
        Placeholder.configure({ placeholder: props.placeholder }),
        CharacterCount.configure({
          limit: props.maxLength,
        }),
      ],
      onFocus() {
        isFocused.value = true
      },
      onBlur() {
        isFocused.value = false
      },
      onUpdate({ editor }) {
        props.inputCallback(editor.getHTML())
      }
    })

    const isFocused = ref(false)
    const showTooltip = ref(false)
    const tooltipStyle = ref()
    const updateLinkModal = ref()
    const updateLinkModalActive = ref(false)
    const showEmojis = ref(false)
    const selectedLink = ref({ name: 'text', url: 'https:/ccsasd/sda.s' })

    const activeLinkHref = computed(() => editor.value.getAttributes('link').href)
    const isLinkButtonEnabled = computed(() => getSelectedText().length > 0)

    function getSelectedText() {
      return document.getSelection().toString()
    }

    function getLinkHref() {
      if (activeLinkHref.value) {
        return activeLinkHref.value
      }
      else {
        return ''
      }
    }

    function onCreateNewLinkSuccess() {
      editor.value
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: updateLinkModal.value.linkUrl })
        .run()
      store.commit(RESET_BASIC_MODAL_DATA)
      updateLinkModalActive.value = false
    }

    function setLink() {
      if (getSelectedText().length === 0) {
        return
      }

      const payload = {
        modalOptions: {
          modalContent: BASIC_MODAL_CONTENT.CREATE_NEW_LINK,
          cancelCallback: null,
          executeCallback: onCreateNewLinkSuccess
        },
        activeModal: MODAL_NAMES.CREATE_NEW_LINK
      }

      store.commit(SET_BASIC_MODAL_DATA, payload)
      updateLinkModalActive.value = true
    }

    document.getElementsByTagName('body')[0].addEventListener('mouseup', onMouseUp)

    function onMouseUp() {
      const text = document.getSelection().toString()
      if (!text.length) {
        showTooltip.value = false
      }
    }

    function mouseUpTextEditor() {
      const selection = document.getSelection()
      const text = selection.toString()
      if (text.length && editor.value.isActive('link')) {
        populateLinkSelection()
        const offsetLeft = document.getElementsByClassName('with-sidebar')[0].offsetLeft
        const range = selection.getRangeAt(0)
        const rect = range.getBoundingClientRect()
        const scrollTop = document.getElementsByClassName('page-content with-sidebar')[0].scrollTop
        const tooltipHeight = 138
        const containerTop = (rect.y + scrollTop - tooltipHeight - 15) + 'px'
        const containerLeft = (rect.x + (rect.width / 2) - offsetLeft - 30) + 'px'
        tooltipStyle.value = `transform: translate3d(${containerLeft}, ${containerTop}, 0px)`
        showTooltip.value = true
      }
    }

    function deleteLink() {
      editor.value.chain().focus().unsetLink().run()
      showTooltip.value = false
    }

    function editLink() {
      const payload = {
        modalOptions: {
          modalContent: BASIC_MODAL_CONTENT.UPDATE_LINK,
          cancelCallback: null,
          executeCallback: onCreateNewLinkSuccess
        },
        activeModal: MODAL_NAMES.CREATE_NEW_LINK
      }

      store.commit(SET_BASIC_MODAL_DATA, payload)
      updateLinkModalActive.value = true
    }

    function populateLinkSelection() {
      selectedLink.value.name = document.getSelection().focusNode.nodeValue
      selectedLink.value.url = activeLinkHref.value
    }

    onUnmounted(() => {
      document.getElementsByTagName('body')[0].removeEventListener('mouseup', onMouseUp)
    })

    function setEmoji(emoji) {
      editor.value.commands.insertContent(emoji)
    }

    return {
      // Variables
      editor,
      isFocused,
      showTooltip,
      tooltipStyle,
      updateLinkModal,
      updateLinkModalActive,
      selectedLink,
      isLinkButtonEnabled,
      showEmojis,

      // Functions
      setLink,
      mouseUpTextEditor,
      getSelectedText,
      deleteLink,
      editLink,
      getLinkHref,
      setEmoji,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

:deep {
  .ProseMirror {
    padding: rem(13.5) rem(16);
    width: 100%;
    height: 100%;
    border: none;

    a {
      color: var(--blue_link);
      font-weight: 500;
    }

    p.is-editor-empty:first-child::before {
      content: attr(data-placeholder);
      float: left;
      color: var(--blue_light_01);
      pointer-events: none;
      height: 0;
      opacity: 50%;
    }
  }

  .ProseMirror.ProseMirror-focused {
    outline: none;
  }
}

.tooltip {
  will-change: transform;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border-radius: rem(8);

  padding: rem(20) rem(18) rem(15) rem(20);

  width: rem(433);
  // TODO: this also account for the arrow in design, this should be smaller when there is an arrow.
  height: rem(138);

  background: white;
  box-shadow: 2px 2px 0 0 #DBDCE1;

  &-contents {
    position: relative;
    width: 100%;
    height: 100%;

    .tooltip-link-alias {
      font-weight: 500;
      padding-bottom: rem(10);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .tooltip-link-url {
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        color: var(--blue_link);
        font-weight: 500;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .tooltip-buttons {
      position: absolute;
      right: 0;
      bottom: 0;

      display: flex;
      gap: rem(12);

      .tooltip-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: rem(6);
        box-shadow: 1px 1px 0 0 rgba(0,0,0,0.1);
        height: rem(33);
        border: none;
        color: white;
        font-family: var(--font-primary);
        cursor: pointer;
        font-size: rem(14);

        p {
          padding-right: rem(7.5);
          text-align: right;
        }

        img {
          padding: rem(6) rem(4);
        }
      }
      .tooltip-button:hover {
        filter: brightness(90%)
      }
      .tooltip-button:active {
        filter: brightness(80%)
      }

      .tooltip-remove {
        width: rem(122);
        background: #E64D4D;
      }

      .tooltip-edit {
        width: rem(108);
        background: #373B52;
      }
    }
  }

  &-arrow {
    position: absolute;
    bottom: rem(-9);
    left: rem(20);
    width: rem(18);
    height: rem(18);

    background: white;
    box-shadow: 0 2px 0 0 #DBDCE1;
    transform: rotate(-45deg);
  }
}

.rich-text-field {


  &__editor {
    width: rem(492);
    min-height: rem(141);
    background: white;
    border-radius: rem(8);
    box-shadow: 2px 2px 0 0 rgba(0,0,0,0.1);
    cursor: text;
    resize: both;
    overflow: auto;

    &.error {
      border: solid 1px var(--red_error)
    }
  }

  &__editor.focused {
    outline-color: #a5aec8;
    outline-width: 1px;
    outline-style: solid;
  }

  &__menu-bar {
    display: flex;
    align-items: center;
    gap: rem(12);
    position: relative;
    margin: rem(8) rem(0) rem(20);

    .emoji-picker {
      position: absolute;
      right: 0;
      top: rem(40);
      width: rem(300);
    }

    &-button {
      position: relative;
      padding: 0;
      border: none;
      background: none;
      width: rem(32);
      height: rem(32);
      &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: rem(20);
        height: rem(20);
      }

    }
    &-right{
        position: absolute;
        right: 0px;
        cursor: pointer;
        padding: rem(10);
        border: none;

        img {
          width: rem(20);
          height: rem(20);
        }
    }
    &-button:hover {
      cursor: pointer;
      filter: brightness(0.8);
    }
    &-button.is-active {
      width: rem(32);
      height: rem(32);
      background: #DAE1EF;
      border-radius: rem(4);
      img {
        filter: brightness(0.6);
      }
    }
    &-button.disabled {
      opacity: 0.6;
      cursor: default;
        filter: brightness(1);
    }
  }
}
</style>
