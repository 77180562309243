<template>
  <div class="update-link-modal">
    <BasicModal :button-right-disabled="!isRightButtonEnabled">
      <template #content>
        <FormWrapper>
          <template #form-content>
            <BasicInputField field-title="Tekst"
                             field-name="text"
                             field-type="text"
                             :value="selectedText"
                             :callback="onText" />
            <!-- Temporarily removed  -->
            <!-- <Dropdown class="link-type" :dropdown-options="options" field-title="Linktype" field-name="link-type" :selected-option="options[0]" :callback="onLinkType" /> -->
            <BasicInputField field-title="Link URL"
                             field-name="link-url"
                             field-type="text"
                             :value="linkValue"
                             placeholder="https://voorbeeld.nl"
                             :callback="onLinkUrl" />
            <!-- Temporarily removed  -->
            <!-- <FileDropbox v-else-if="linkType.id === 1" :allowed-mime-types="['application/pdf']" style-preset="small" :max-amount-of-files="1" /> -->
          </template>
        </FormWrapper>
      </template>
    </BasicModal>
  </div>
</template>

<script>
import BasicModal from '@/components/elements/basicModal/BasicModal'
import BasicInputField from '@/components/elements/inputFields/BasicInputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
// import Dropdown from '@/components/yo-form/Dropdown'
// import FileDropbox from '@/components/elements/FileDropbox'
import { computed, ref } from '@vue/runtime-core'

export default {
  name: 'UpdateLinkModal',
  components: {
    BasicModal,
    BasicInputField,
    FormWrapper,
    // Dropdown,
    // FileDropbox
  },
  props: {
    selectedText: {
      type: String,
      required: true
    },
    linkValue: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    const options = computed(() => [{ id: 0, name: 'URL' }, { id: 1, name: 'Bestand' }])
    const isRightButtonEnabled = computed(() => text.value.length > 0 /*&& linkType.value*/ && linkUrl.value.length > 0)
    const text = ref(props.selectedText)
    // const linkType = ref(options.value[0])
    const linkUrl = ref(props.linkValue)

    function onText(result) {
      text.value = result.data
    }

    // function onLinkType(result) {
    //   linkType.value = result
    // }

    function onLinkUrl(result) {
      linkUrl.value = result.data
    }

    return {
      options,
      text,
      // linkType,
      linkUrl,
      isRightButtonEnabled,

      onText,
      // onLinkType,
      onLinkUrl,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.link-type {
  margin-top: rem(32);
}
</style>
